import {
  WppTypography,
  // WppButton, WppIconDownload
} from '@wppopen/components-library-react'
// import { useOs } from '@wppopen/react'
import clsx from 'clsx'

import { ReactComponent as LoadingIllustration } from 'components/campaignPlanGenerator/campaignPlanResult/assets/loading-illustration.svg'
// import testPdf from 'components/campaignPlanGenerator/campaignPlanResult/assets/test.pdf'
import styles from 'components/campaignPlanGenerator/campaignPlanResult/CampaignPlanResult.module.scss'
import { GeneratedScenario } from 'components/campaignPlanGenerator/campaignPlanResult/generatedDataMock'
import { Flex } from 'components/common/flex/Flex'
import { PieChart } from 'components/common/pieChart/PieChart'
// import FileHelper from 'utils/FileHelper'

// const copyTypeData = [
//   { value: 70, name: 'Video' },
//   { value: 10, name: 'High impact image' },
//   { value: 20, name: 'Image' },
// ]

// const fakeProjectId = '6ecc6c08-d3e6-4a2b-8d5b-aadf41926bfd'

interface Props {
  isGenerated: boolean
  data: GeneratedScenario
}

export const CampaignPlanResult = ({ isGenerated, data }: Props) => {
  // const {
  //   osApi,
  //   osContext: { tenant },
  // } = useOs()

  // const handleSaveToFiles = async () => {
  //   const response = await fetch(testPdf)
  //   const blob = await response.blob()

  //   // const formData = new FormData()
  //   // formData.append('file', blob, 'test.pdf') // TODO change file name

  //   const fileHelper = new FileHelper(osApi.getAccessToken())
  //   await fileHelper.uploadFile(blob, tenant.id, tenant.homeUrl, fakeProjectId)
  // }

  if (!isGenerated) {
    return (
      <Flex justify="center" align="center" className={styles.container}>
        <LoadingIllustration />
      </Flex>
    )
  }

  return (
    <div className={styles.container}>
      <Flex justify="between" className={styles.header}>
        <WppTypography type="3xl-heading">Campaign Plan</WppTypography>
        {/* <WppButton variant="primary" onClick={handleSaveToFiles}>
          <WppIconDownload slot="icon-start" />
          Save to Files
        </WppButton> */}
      </Flex>
      <Flex gap={41} className={styles.section}>
        <div>
          <WppTypography type="s-strong" className={styles.grayLabel}>
            Channel Mix
          </WppTypography>
          <PieChart data={data.channelMix} width={390} height={270} className={styles.channelMixChart} />
        </div>
        <Flex direction="column" gap={12} className={styles.rightSection}>
          <Flex gap={32} align="center">
            <WppTypography type="s-strong" className={clsx(styles.grayLabel, styles.rightLabel)}>
              Reach and freq
            </WppTypography>
            <WppTypography type="s-body">{data.reachAndFrequency}</WppTypography>
          </Flex>
          <Flex gap={32} align="center">
            <WppTypography type="s-strong" className={clsx(styles.grayLabel, styles.rightLabel)}>
              Phasing
            </WppTypography>
            {data.phasing === 'Standard' ? (
              <div className={clsx(styles.tag, styles.tagGreen)}>
                <WppTypography type="s-midi" className={styles.tagTextGreen}>
                  Standard
                </WppTypography>
              </div>
            ) : (
              <WppTypography type="s-body">{data.phasing}</WppTypography>
            )}
          </Flex>
          <Flex gap={32}>
            <WppTypography type="s-strong" className={clsx(styles.grayLabel, styles.rightLabel)}>
              Phasing rationale
            </WppTypography>
            <WppTypography type="s-body">{data.phasingRationale}</WppTypography>
          </Flex>
        </Flex>
        {/* <Flex gap={51}>
          <WppTypography type="s-strong" className={styles.grayLabel}>
            Reach and freq
          </WppTypography>
          <Flex direction="column" gap={8}>
            <Flex gap={8} align="center">
              <WppTypography type="s-body">TV</WppTypography>
              <div className={clsx(styles.tag, styles.tagGreen)}>
                <WppTypography type="s-midi" className={styles.tagTextGreen}>
                  Platform Awareness
                </WppTypography>
              </div>
            </Flex>
            <Flex gap={8} align="center">
              <WppTypography type="s-body">OOH</WppTypography>
              <div className={clsx(styles.tag, styles.tagBlue)}>
                <WppTypography type="s-midi" className={styles.tagTextBlue}>
                  IP Awareness
                </WppTypography>
              </div>
            </Flex>
            <Flex gap={8} align="center">
              <WppTypography type="s-body">Social</WppTypography>
              <div className={clsx(styles.tag, styles.tagRed)}>
                <WppTypography type="s-midi" className={styles.tagTextRed}>
                  Platform Awareness and Conversion
                </WppTypography>
              </div>
            </Flex>
          </Flex>
        </Flex> */}
      </Flex>
      <table className={styles.table}>
        <tr>
          <th />
          <th>
            <WppTypography type="s-strong">TV</WppTypography>
          </th>
          <th>
            <WppTypography type="s-strong">OOH</WppTypography>
          </th>
          <th>
            <WppTypography type="s-strong">BVOD</WppTypography>
          </th>
          <th>
            <WppTypography type="s-strong">Audio</WppTypography>
          </th>
        </tr>
        <tr>
          <td>
            <WppTypography type="s-strong">Role for channel</WppTypography>
          </td>
          <td>
            <WppTypography type="xs-body">{data.roleForChannel.TV}</WppTypography>
          </td>
          <td>
            <WppTypography type="xs-body">{data.roleForChannel.OOH}</WppTypography>
          </td>
          <td>
            <WppTypography type="xs-body">{data.roleForChannel.BVOD}</WppTypography>
          </td>
          <td>
            <WppTypography type="xs-body">{data.roleForChannel.Audio}</WppTypography>
          </td>
        </tr>
        <tr>
          <td>
            <WppTypography type="s-strong">Partners</WppTypography>
          </td>
          <td>
            <WppTypography type="xs-body">{data.partners.TV}</WppTypography>
          </td>
          <td>
            <WppTypography type="xs-body">{data.partners.OOH}</WppTypography>
          </td>
          <td>
            <WppTypography type="xs-body">{data.partners.BVOD}</WppTypography>
          </td>
          <td>
            <WppTypography type="xs-body">{data.partners.Audio}</WppTypography>
          </td>
        </tr>
        <tr>
          <td>
            <WppTypography type="s-strong">Copy length</WppTypography>
          </td>
          <td>
            <WppTypography type="xs-body">{data.copyLength.TV}</WppTypography>
          </td>
          <td>
            <WppTypography type="xs-body">{data.copyLength.OOH}</WppTypography>
          </td>
          <td>
            <WppTypography type="xs-body">{data.copyLength.BVOD}</WppTypography>
          </td>
          <td>
            <WppTypography type="xs-body">{data.copyLength.Audio}</WppTypography>
          </td>
        </tr>
        <tr>
          <td>
            <WppTypography type="s-strong">Copy length rationale</WppTypography>
          </td>
          <td>
            <WppTypography type="xs-body">{data.copyLengthRationale.TV}</WppTypography>
          </td>
          <td>
            <WppTypography type="xs-body">{data.copyLengthRationale.OOH}</WppTypography>
          </td>
          <td>
            <WppTypography type="xs-body">{data.copyLengthRationale.BVOD}</WppTypography>
          </td>
          <td>
            <WppTypography type="xs-body">{data.copyLengthRationale.Audio}</WppTypography>
          </td>
        </tr>
      </table>
    </div>
  )
}
