// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MP7Il::part(typography){color:var(--wpp-grey-color-800)}.ZmV_u::part(datepicker-container){width:100%}.joHkC{display:block}.joHkC::part(typography){color:var(--wpp-grey-color-800)}.RjiHp{display:block;margin-bottom:10px}.RjiHp::part(typography){color:var(--wpp-grey-color-800)}.OfhLq{margin-top:6px}.MHsxa::part(button){height:40px}`, "",{"version":3,"sources":["webpack://./src/components/campaignPlanGenerator/campaignPlanForm/CampaignPlanForm.module.scss"],"names":[],"mappings":"AACE,yBACE,+BAAA,CAKF,mCACE,UAAA,CAIJ,OACE,aAAA,CAEA,yBACE,+BAAA,CAIJ,OACE,aAAA,CACA,kBAAA,CAEA,yBACE,+BAAA,CAIJ,OACE,cAAA,CAIA,qBACE,WAAA","sourcesContent":[".inputLabel {\n  &::part(typography) {\n    color: var(--wpp-grey-color-800);\n  }\n}\n\n.datePicker {\n  &::part(datepicker-container) {\n    width: 100%;\n  }\n}\n\n.textAreaLabel {\n  display: block;\n\n  &::part(typography) {\n    color: var(--wpp-grey-color-800);\n  }\n}\n\n.textAreaSublabel {\n  display: block;\n  margin-bottom: 10px;\n\n  &::part(typography) {\n    color: var(--wpp-grey-color-800);\n  }\n}\n\n.aiIcon {\n  margin-top: 6px;\n}\n\n.submitBtn {\n  &::part(button) {\n    height: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputLabel": `MP7Il`,
	"datePicker": `ZmV_u`,
	"textAreaLabel": `joHkC`,
	"textAreaSublabel": `RjiHp`,
	"aiIcon": `OfhLq`,
	"submitBtn": `MHsxa`
};
export default ___CSS_LOADER_EXPORT___;
