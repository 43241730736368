import * as echarts from 'echarts'
import { useRef, useEffect } from 'react'

interface Props {
  data: { value: number; name: string }[]
  width: number
  height: number
  className?: string
}

export const PieChart = ({ data, width, height, className }: Props) => {
  const chartPieRef = useRef(null)

  useEffect(() => {
    if (!chartPieRef.current) return

    var myChart = echarts.init(chartPieRef.current)
    myChart.resize({
      width,
      height,
    })

    const option = {
      color: ['#C2D4FF', '#066FEF', '#00095B', '#FF9900'],
      // legend: {
      //   orient: 'horizontal',
      //   right: 'center',
      //   bottom: -16,
      //   icon: 'circle',
      //   itemWidth: 10,
      //   itemHeight: 10,
      // },
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          label: {
            show: true,
            color: '#8B919A',
            formatter: '{b} ({c}%)',
          },
          labelLine: {
            show: true,
            lineStyle: {
              color: '#8B919A',
            },
          },
          data: data,
        },
      ],
    }

    myChart.setOption(option)
  }, [chartPieRef, data, width, height])

  return <div ref={chartPieRef} className={className} />
}
