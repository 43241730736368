import { WppTypography, WppCard } from '@wppopen/components-library-react'
import { useState } from 'react'

import { CampaignPlanForm } from 'components/campaignPlanGenerator/campaignPlanForm/CampaignPlanForm'
import styles from 'components/campaignPlanGenerator/CampaignPlanGenerator.module.scss'
import { CampaignPlanResult } from 'components/campaignPlanGenerator/campaignPlanResult/CampaignPlanResult'
import { generatedDataMock } from 'components/campaignPlanGenerator/campaignPlanResult/generatedDataMock'
import { Flex } from 'components/common/flex/Flex'

export const CampaignPlanGenerator = () => {
  const [generatedIndexData, setGeneratedIndexData] = useState<null | number>(null) // TODO swithc to false

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div>
          <WppTypography type="3xl-heading" tag="h1">
            Generate
          </WppTypography>
        </div>
        <WppTypography type="s-body" tag="p" className={styles.description}>
          Define your marketing moments overview, objectives and share with your team
        </WppTypography>
      </div>
      <WppCard className={styles.card}>
        <Flex>
          <div className={styles.form}>
            <CampaignPlanForm onGenerated={scenarioIndex => setGeneratedIndexData(scenarioIndex)} />
          </div>
          <CampaignPlanResult isGenerated={typeof generatedIndexData === 'number'} data={generatedDataMock[0]} />
        </Flex>
      </WppCard>
    </div>
  )
}
