import { useOs } from '@wppopen/react'
import { BrowserRouter } from 'react-router-dom'

import styles from 'app/App.module.scss'
import { CampaignPlanGenerator } from 'components/campaignPlanGenerator/CampaignPlanGenerator'

export function App() {
  const { osContext } = useOs()

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <div className={styles.container}>
        <CampaignPlanGenerator />
      </div>
    </BrowserRouter>
  )
}
