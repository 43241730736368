import { WppToggle } from '@wppopen/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { useCommonLabelProps } from 'components/common/utils'
import { useField } from 'hooks/form/useField'
import { useProvideFieldFocus } from 'hooks/form/useProvideFieldFocus'

interface Props extends Omit<ComponentPropsWithoutRef<typeof WppToggle>, 'checked' | 'onChange'> {
  name: string
}

export const FormToggle = forwardRef<HTMLWppToggleElement, Props>(
  ({ name, onWppBlur, onWppChange, labelConfig, labelTooltipConfig, ...rest }, ref) => {
    const {
      field: { ref: fieldRef, value, onChange, onBlur },
    } = useField({ name })

    const innerRef = useRef<HTMLWppToggleElement>(null)

    useProvideFieldFocus({
      fieldRef,
      setFocus: () => innerRef.current?.setFocus(),
    })

    const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })

    return (
      <WppToggle
        {...rest}
        {...labelProps}
        ref={mergeRefs([innerRef, ref])}
        name={name}
        checked={value}
        onWppChange={e => {
          onChange(e.detail.checked)
          onWppChange?.(e)
        }}
        onWppBlur={e => {
          onBlur()
          onWppBlur?.(e)
        }}
      />
    )
  },
)
