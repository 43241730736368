import { WppTypography, WppButton, WppLabel } from '@wppopen/components-library-react'
import { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import * as zod from 'zod'

import { ReactComponent as AIIcon } from 'components/campaignPlanGenerator/campaignPlanForm/assets/ai-icon.svg'
import styles from 'components/campaignPlanGenerator/campaignPlanForm/CampaignPlanForm.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { FormDatepicker } from 'components/form/formDatepicker/FormDatepicker'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { FormToggle } from 'components/form/formToggle/FormToggle'
import { useForm } from 'hooks/form/useForm'

// TODO move to a shared file
const formSchema = zod.object({
  campaignCategory: zod.string().min(1), // TODO maybe add enum
  campaignSize: zod.string().min(1), // TODO maybe add enum
  country: zod.string().min(1),
  genre: zod.string().min(1),
  launchDate: zod.date(),
  theatricalWindow: zod.boolean(),
  brief: zod.string().min(1),
})

const formInitialValues = {
  campaignCategory: '',
  campaignSize: '',
  country: '',
  genre: '',
  launchDate: null,
  theatricalWindow: false,
  brief:
    'Planning best practice for each campaign type and genre such as channel mix, role for channel, reach minimums, best formats and recommended copy as well as benchmark data bases and phasing guidance',
}

const campaignCategoryOptions = [
  { value: 'film', label: 'Film' },
  { value: 'series', label: 'Series' },
  { value: 'sport', label: 'Sport' },
]

const campaignSizeOptions = [
  { value: 's', label: 'Small' },
  { value: 'm', label: 'Medium' },
  { value: 'l', label: 'Large' },
]

const countrySizeOptions = [
  { value: 'UK', label: 'UK' },
  { value: 'DE', label: 'DE' },
  { value: 'FR', label: 'FR' },
  { value: 'IT', label: 'IT' },
  { value: 'ES', label: 'ES' },
  { value: 'TK', label: 'TK' },
]

const genreOptions = [
  { value: 'horror', label: 'Horror' },
  { value: 'action', label: 'Action' },
  { value: 'fantasy', label: 'Fantasy' },
  { value: 'romcom', label: 'Romcom' },
]
interface Props {
  onGenerated: (scenarioIndex: number) => void
}

export const CampaignPlanForm = ({ onGenerated }: Props) => {
  const [isSubmittingMock, setIsSubmittingMock] = useState(false)

  const form = useForm({
    defaultValues: formInitialValues,
    validationSchema: formSchema,
  })

  const {
    handleSubmit,
    formState: { isValid },
  } = form

  const onSubmit = handleSubmit(data => {
    setIsSubmittingMock(true)

    const brief = data.brief.toLowerCase()
    var scenarioIndex = 0
    if (brief.includes('accurate plan')) {
      scenarioIndex = 0
    } else if (brief.includes('q4') || brief.includes('shopping')) {
      scenarioIndex = 1
    } else if (brief.includes('may') || brief.includes('cluttered')) {
      scenarioIndex = 2
    } else if (brief.includes('sep') || brief.includes('september') || brief.includes('back to school')) {
      scenarioIndex = 3
    } else if (brief.includes('biggest') || brief.includes('releasement')) {
      scenarioIndex = 4
    } else if (brief.includes('competition') || brief.includes('local') || brief.includes('contents')) {
      scenarioIndex = 5
    }

    setTimeout(() => {
      setIsSubmittingMock(false)
      onGenerated(scenarioIndex)
    }, 2500)
  })

  return (
    <FormProvider {...form}>
      <Flex direction="column" gap={24}>
        <Flex justify="between" align="center">
          <WppTypography type="s-strong" className={styles.inputLabel}>
            Campaign category
          </WppTypography>
          <FormSelect
            name="campaignCategory"
            required
            options={campaignCategoryOptions}
            placeholder="Select a category"
          />
        </Flex>
        <Flex justify="between" align="center">
          <WppTypography type="s-strong" className={styles.inputLabel}>
            Campaign size
          </WppTypography>
          <FormSelect name="campaignSize" required options={campaignSizeOptions} placeholder="Select a size" />
        </Flex>
        <Flex justify="between" align="center">
          <WppTypography type="s-strong" className={styles.inputLabel}>
            Country
          </WppTypography>
          <FormSelect name="country" required options={countrySizeOptions} placeholder="Select a country" />
        </Flex>
        <Flex justify="between" align="center">
          <WppTypography type="s-strong" className={styles.inputLabel}>
            Genre
          </WppTypography>
          <FormSelect name="genre" required options={genreOptions} placeholder="Select a genre" />
        </Flex>
        <FormDatepicker
          className={styles.datePicker}
          required
          name="launchDate"
          labelConfig={{ text: 'Launch date' }}
        />
        <Flex justify="between">
          <WppLabel typography="s-body">Theatrical window</WppLabel>
          <FormToggle name="theatricalWindow" />
        </Flex>
        <div>
          <WppTypography type="s-strong" className={styles.textAreaLabel}>
            Brief
          </WppTypography>
          <WppTypography type="xs-body" className={styles.textAreaSublabel}>
            Break down the overall goal into specific, measurable objectives.
          </WppTypography>
          <FormTextareaInput name="brief" warningThreshold={380} charactersLimit={400} />
        </div>
        <Flex justify="end">
          <WppButton
            variant="primary"
            type="submit"
            disabled={!isValid}
            loading={isSubmittingMock}
            onClick={onSubmit}
            className={styles.submitBtn}
          >
            <div slot="icon-start" className={styles.aiIcon}>
              <AIIcon />
            </div>
            Generate Campaign
          </WppButton>
        </Flex>
      </Flex>
    </FormProvider>
  )
}
