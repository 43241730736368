interface chartData {
  value: number
  name: string
}

export interface GeneratedScenario {
  title: string
  channelMix: chartData[]
  reachAndFrequency: string
  phasing: string
  phasingRationale: string
  roleForChannel: { TV: string; OOH: string; BVOD: string; Audio: string }
  partners: { TV: string; OOH: string; BVOD: string; Audio: string }
  copyLength: { TV: string; OOH: string; BVOD: string; Audio: string }
  copyLengthRationale: { TV: string; OOH: string; BVOD: string; Audio: string }
}

export const generatedDataMock: GeneratedScenario[] = [
  {
    title: 'Campaign Plan',
    channelMix: [
      { value: 48, name: 'TV' },
      { value: 37, name: 'OOH' },
      { value: 6, name: 'BVOD' },
      { value: 9, name: 'Audio' },
    ],
    reachAndFrequency: '90% @1+, 19% @5+',
    phasing: 'Standard',
    phasingRationale:
      'For large formats 15 days ago and screen planning 1 week ago to boost trailer. And 2 weeks launch period to maximise visibility after releasement',
    roleForChannel: {
      TV: 'maximizing 1+ reach and aligning with relevant programme',
      OOH: 'creating incremental reach to broad audience',
      BVOD: 'digital video at scale and to reach light TV viewers through smart TV',
      Audio: 'creating incremental reach with low cost and high attention',
    },
    partners: {
      TV: 'Top 7 measured channels',
      OOH: 'Kentvizyon, Casa, Matador, Reklamist, MaxiBoard, Capsule , Inova',
      BVOD: '7 measured TV Channels on smart TV, Youtube , Non YT -Programmatic , YT & Web inventory of Top TV content',
      Audio: 'Karnaval, Power Group, Doğuş Group & Spotify',
    },
    copyLength: {
      TV: '30" & 20"',
      OOH: '15" (digital screens) & static formats',
      BVOD: '30" & 10"',
      Audio: '15"',
    },
    copyLengthRationale: {
      TV: 'Start with 30"-standard trailer and 20" as of the last week due to optimum completion rate',
      OOH: 'static formats for large ones and max 15" for digital screens to increase daily frequency',
      BVOD: '30" (trailer) on YT-programmatic and YT&Web inventory of top TV contents & 10" on smart TV inventory as frame ad format',
      Audio: 'max 15" on radio to avoid decreasing on completion rate',
    },
  },
  {
    title: 'Campaign Plan',
    channelMix: [
      { value: 41, name: 'TV' },
      { value: 32, name: 'OOH' },
      { value: 13, name: 'BVOD' },
      { value: 14, name: 'Audio' },
    ],
    reachAndFrequency: '90% @1+, 19% @5+',
    phasing: 'Focus on less cluttered periods and areas',
    phasingRationale:
      'Because of shopping periods and new year campaigns in Q4, total campaign timing can be extended to 4 or 5 weeks to capture less cluttered periods.',
    roleForChannel: {
      TV: 'maximizing 1+ reach and aligning with lower cost programme because of expensive period in Turkey',
      OOH: 'creating incremental reach to broad audience, weightly digital screens because of cluttered period in OOH',
      BVOD: 'digital video at scale with a higher weight on TV content targeting light TV viewers with optimised cost',
      Audio: 'creating incremental reach with low cost and high attention',
    },
    partners: {
      TV: 'Top 7 measured channels & 8 minor channels (20% of total fair share on TV)',
      OOH: 'Kentvizyon, Casa, Matador, Reklamist, MaxiBoard, Capsule , Inova',
      BVOD: '7 measured TV Channels on smart TV, Youtube , Non YT -Programmatic , YT & Web inventory of Top TV content',
      Audio: 'Karnaval, Power Group, Doğuş Group & Spotify',
    },
    copyLength: {
      TV: '30" & 15" (to decrease total cost)',
      OOH: '15" (digital screens) & static formats',
      BVOD: '30" & 10"',
      Audio: '15"',
    },
    copyLengthRationale: {
      TV: 'Start with 30"-standard trailer and 15" as of the last week to decrease total cost',
      OOH: 'static formats for large ones and max 15" for digital screens to increase daily frequency',
      BVOD: '30" (trailer) on YT-programmatic and YT&Web inventory of top TV contents & 10" on smart TV inventory as frame ad format',
      Audio: 'max 15" on radio to avoid decreasing on completion rate',
    },
  },
  {
    title: 'Campaign Plan',
    channelMix: [
      { value: 40, name: 'TV' },
      { value: 35, name: 'OOH' },
      { value: 13, name: 'BVOD' },
      { value: 12, name: 'Audio' },
    ],
    reachAndFrequency: '90% @1+, 19% @5+',
    phasing: 'Focus on cost efficient areas to avoid additional inflation',
    phasingRationale:
      'May is highly cluttered and total campaign timing can be extended to 4 or 5 weeks to capture less cluttered periods such as April or June.',
    roleForChannel: {
      TV: 'maximizing 1+ reach via programme selection according to seasonality and channel selection with considering special periods such as national holidays and final of TV programmes. Additionaly evaluate frame ad option to position inside the content directly',
      OOH: 'creating incremental reach to broad audience, weightly digital screens to decrease total cost and also focus on long-term deals for large static formats',
      BVOD: 'digital video at scale with a higher weight on TV content targeting light TV viewers with optimised cost and focus on YT & web inventory due to finals of top programmes',
      Audio:
        'creating incremental reach with low cost and high attention, additionaly focus on package deals such as sponsorships',
    },
    partners: {
      TV: 'Top 7 measured channels & 8 minor channels (20% of total fair share on TV)',
      OOH: 'Kentvizyon, Casa, Matador, Reklamist, MaxiBoard, Capsule , Inova',
      BVOD: '7 measured TV Channels on smart TV, Youtube , Non YT -Programmatic , YT & Web inventory of Top TV content',
      Audio: 'Karnaval, Power Group, Doğuş Group & Spotify',
    },
    copyLength: {
      TV: '30" & 15" & 8"',
      OOH: '15" (digital screens) & static formats',
      BVOD: '30" & 15" & 10"',
      Audio: '15"',
    },
    copyLengthRationale: {
      TV: 'start with 30"-standard trailer and 8" frae ad format to capture the inside of contents & 15" as of the last week to decrease total cost',
      OOH: 'static formats for large ones and max 15" for digital screens to increase daily frequency',
      BVOD: '30" (trailer) & 15" on YT-programmatic and YT&Web inventory of top TV contents & 10" on smart TV inventory as frame ad format',
      Audio: 'max 15" on radio to avoid decreasing on completion rate',
    },
  },
  {
    title: 'Campaign Plan',
    channelMix: [
      { value: 35, name: 'TV' },
      { value: 35, name: 'OOH' },
      { value: 15, name: 'BVOD' },
      { value: 15, name: 'Audio' },
    ],
    reachAndFrequency: '90% @1+, 19% @5+',
    phasing: 'High impact and avoiding limited ad break issues with front-loading',
    phasingRationale:
      'Sep has limited ad area especially for broadcasting mediums. And, Oct is one of the most expensive month. Front loading as of pre-launch ',
    roleForChannel: {
      TV: 'reaching the optimum 1+ with linear TV-30% frame ad usage because of limited ad break by TV channels (especially for the first episodes of top programmes) at the begining of new season',
      OOH: 'creating incremental reach to broad audience and trying to find large format for the last 4 months of the year',
      BVOD: 'digital video at scale with a higher weight on TV content targeting light TV viewers with optimised cost and especially focus on the first episodes of top programmes',
      Audio:
        'creating incremental reach with low cost and high attention, additionaly focus on special products placements such as DJ Talk, theme airings, etc.',
    },
    partners: {
      TV: 'Top 7 measured channels & 8 minor channels (20% of total fair share on TV)',
      OOH: 'Kentvizyon, Casa, Matador, Reklamist, MaxiBoard, Capsule , Inova',
      BVOD: '7 measured TV Channels on smart TV, Youtube , Non YT -Programmatic , YT & Web inventory of Top TV content',
      Audio: 'Karnaval, Power Group, Doğuş Group & Spotify',
    },
    copyLength: {
      TV: '30" & 15" & 8"',
      OOH: '15" (digital screens) & static formats',
      BVOD: '30" & 15" & 10"',
      Audio: '15" & 60"',
    },
    copyLengthRationale: {
      TV: 'start with 30"-standard trailer and 8" frame ad format to capture the inside of contents & 15" as of the last week to decrease total cost',
      OOH: 'static formats for large ones and max 15" for digital screens to increase daily frequency',
      BVOD: '30" (trailer) & 15" on YT-programmatic and YT&Web inventory of top TV contents & 10" on smart TV inventory as frame ad format',
      Audio:
        'max 15" on radio to avoid decreasing on completion rate & 60" for special usages such as DJ talks or product placement',
    },
  },
  {
    title: 'Campaign Plan',
    channelMix: [
      { value: 50, name: 'TV' },
      { value: 35, name: 'OOH' },
      { value: 5, name: 'BVOD' },
      { value: 10, name: 'Audio' },
    ],
    reachAndFrequency: '90% @1+, 19% @5+',
    phasing: 'Standard',
    phasingRationale:
      'For large formats 15 days ago and screen planning 1 week ago to boost trailer. And 2 weeks launch period to maximise visibility after releasement',
    roleForChannel: {
      TV: 'capture free TV viewers to direct your owned platforms and miaximise 1+ reach with borad channel mix',
      OOH: 'creating incremental reach to broad audience and dominate large areas in first big 3 cities',
      BVOD: 'digital video at scale and to reach light TV viewers through smart TV and connected TV users',
      Audio:
        'creating incremental reach with low cost and high attention, especially focus on drive-time to capture potential SVOD users (white-collars) on traffic.',
    },
    partners: {
      TV: 'Top 7 measured channels & 8 minor channels (20% of total fair share on TV)',
      OOH: 'Kentvizyon, Casa, Matador, Reklamist, MaxiBoard, Capsule , Inova ',
      BVOD: '7 measured TV Channels on smart TV, Youtube , Non YT -Programmatic , YT & Web inventory of Top TV content',
      Audio: 'Karnaval, Power Group, Doğuş Group & Spotify',
    },
    copyLength: {
      TV: '30" & 20"',
      OOH: '15" (digital screens) & static formats',
      BVOD: '30" & 15" & 10"',
      Audio: '15"',
    },
    copyLengthRationale: {
      TV: 'Start with 30"-standard trailer and 20" as of the last week due to optimum completion rate',
      OOH: 'static formats for large ones and max 15" for digital screens to increase daily frequency',
      BVOD: '30" (trailer) & 15" on YT-programmatic and YT&Web inventory of top TV contents & 10" on smart TV inventory as frame ad format',
      Audio: 'max 15" on radio to avoid decreasing on completion rate',
    },
  },
  {
    title: 'Campaign Plan',
    channelMix: [
      { value: 45, name: 'TV' },
      { value: 39, name: 'OOH' },
      { value: 8, name: 'BVOD' },
      { value: 8, name: 'Audio' },
    ],
    reachAndFrequency: '90% @1+, 19% @5+',
    phasing: 'Standard',
    phasingRationale:
      'For large formats 15 days ago and screen planning 1 week ago to boost trailer. And 2 weeks launch period to capture broad audience',
    roleForChannel: {
      TV: 'maximizing 1+ reach and aligning with relevant programme according to your owned content',
      OOH: 'creating incremental reach to broad audience via mainly large formats such as megawalls, giantboard and billboards, etc.',
      BVOD: 'digital video at scale and to reach light TV viewers through smart TV',
      Audio: 'creating incremental reach with low cost and high attention',
    },
    partners: {
      TV: 'Top 7 measured channels with preimum positioning',
      OOH: 'Kentvizyon, Casa, Matador, Reklamist, MaxiBoard, Capsule , Inova ',
      BVOD: '7 measured TV Channels on smart TV, Youtube , Non YT -Programmatic , YT & Web inventory of Top TV content',
      Audio: 'Karnaval, Power Group, Doğuş Group & Spotify',
    },
    copyLength: {
      TV: '30" & 20"',
      OOH: '15" (digital screens) & static formats',
      BVOD: '30" & 10"',
      Audio: '15"',
    },
    copyLengthRationale: {
      TV: 'Start with 30"-standard trailer and 20" as of the last week due to optimum completion rate',
      OOH: 'static formats for large ones and max 15" for digital screens to increase daily frequency',
      BVOD: '30" (trailer) on YT-programmatic and YT&Web inventory of top TV contents & 10" on smart TV inventory as frame ad format',
      Audio: 'max 15" on radio to avoid decreasing on completion rate',
    },
  },
]
