// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v5syf{max-width:var(--wpp-os-content-max-width);margin:0 auto;padding:33px 36px 31px}.yRDeb{margin-bottom:33px}.SC9aD{margin-top:8px}.SC9aD::part(typography){color:var(--wpp-grey-color-800)}.DmAwm::part(card){padding:24px 24px 30px 30px}.kqfDk{min-width:403px;padding-right:24px;border-right:1px solid var(--wpp-grey-color-300)}`, "",{"version":3,"sources":["webpack://./src/components/campaignPlanGenerator/CampaignPlanGenerator.module.scss"],"names":[],"mappings":"AAAA,OACE,yCAAA,CACA,aAAA,CACA,sBAAA,CAGF,OACE,kBAAA,CAGF,OACE,cAAA,CAEA,yBACE,+BAAA,CAKF,mBACE,2BAAA,CAIJ,OACE,eAAA,CACA,kBAAA,CACA,gDAAA","sourcesContent":[".container {\n  max-width: var(--wpp-os-content-max-width);\n  margin: 0 auto;\n  padding: 33px 36px 31px;\n}\n\n.titleContainer {\n  margin-bottom: 33px;\n}\n\n.description {\n  margin-top: 8px;\n\n  &::part(typography) {\n    color: var(--wpp-grey-color-800);\n  }\n}\n\n.card {\n  &::part(card) {\n    padding: 24px 24px 30px 30px;\n  }\n}\n\n.form {\n  min-width: 403px;\n  padding-right: 24px;\n  border-right: 1px solid var(--wpp-grey-color-300);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `v5syf`,
	"titleContainer": `yRDeb`,
	"description": `SC9aD`,
	"card": `DmAwm`,
	"form": `kqfDk`
};
export default ___CSS_LOADER_EXPORT___;
